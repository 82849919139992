import React from "react"
import { connect } from "react-redux"

import Layout from '../../../components/layout'
import SEO from '../../../components/seo'

import {
  formatMarkdown,
  formatOffice,
  formatContacts,
  formatImageField,
  formatVideoField,
  formatBrand,
  longformFormater,
} from "../../../utils/previewFormaters"

import {
  contentfulClient,
  getContentfulEntryID,
} from "../../../utils/contentfulClient"

import OfficeSingle from "../../../content/offices/OfficeSingle"

class OfficeSinglePreview extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: null,
    }
    this.entryID = getContentfulEntryID()
  }

  componentDidMount() {
    if (this.entryID) {
      contentfulClient()
        .getEntries({
          "sys.id": this.entryID,
          content_type: `office`,
          include: 10,
          limit: 1,
        })
        .then(entries => this.formatData(entries))
        .catch(err => console.log(err))
    }
  }

  formatData(entries) {
    let entry = entries.items[0].fields
    let data = { office: null }

    if (entry) {
      data.office = {
        officeName: entry.officeName,
        officeAbout: entry.officeAbout
          ? formatMarkdown(entry.officeAbout)
          : null,
        contacts: entry.contacts ? formatContacts(entry.contacts) : null,
        longform: entry.longform ? longformFormater(entry.longform) : null,
        facebookUrl: entry.facebookUrl,
        twitterUrl: entry.twitterUrl,
        facebookUrl: entry.facebookUrl,
        officeImage: entry.officeImage
          ? formatImageField(entry.officeImage)
          : null,
        videoHeader: entry.videoHeader
          ? formatVideoField(entry.videoHeader)
          : null,
        officeAddress: entry.officeAddress,
        officeStreetAdress: entry.officeStreetAdress
          ? formatMarkdown(entry.officeStreetAdress)
          : null,
      }

      this.setState({
        data: data,
      })
    }
  }

  render() {
    return (
      <Layout>
        <SEO title={`Office Preview`} description={`Office Preview`} />
        <div style={{ minHeight: `100vh` }}>
          {this.state.data && <OfficeSingle data={this.state.data} />}
        </div>
      </Layout>
    )
  }
}

export default OfficeSinglePreview
